import React from "react";
import {Helmet} from "react-helmet";
//import BG_IMAGE from "../../images/bg/bgimg_auto.jpg";
import {Settings} from "../../config/Settings";


const BotPageRoom = ({pageContext}) =>{
    //console.log(pageContext);
    const title = pageContext.title;
    const title_branded = `${title} - Tidsrom`;
    const description = pageContext.description;
    const url = pageContext.url;
    const image = pageContext.imageUrl || "https://mm.dimu.org/image/0136MvWaorfN";
    const pageUrl = Settings.PAGE_URL;
    //urlNoSlash = url[url.length - 1] === "/" ? url.substring(0, url.length - 1) : url;
    return (
        <>
        <Helmet>
            <title>{title_branded}</title>
            <meta name="title" content={title_branded}></meta>
            <meta name="description" content={description}></meta>
            
            <meta property="og:title" content={title_branded}/>
            <meta property="og:description" content={description}/>
            <meta property="og:image" content={image}/>
            <meta property="og:url" content={url}/>
            <meta property="og:type" content="article"/>


            <meta property="og:locale" content="nb_NO" />
            <meta property="og:locale:alternate" content="en_US" />
            <meta property="og:site_name" content="Tidsrom" />

            <meta name="twitter:title" content={title_branded}/>
            <meta name="twitter:description" content={description}/>
            <meta name="twitter:image" content={image}/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:creator" content="@Folkemuseet" />
        </Helmet>
        <h1>{pageContext.title}</h1>
        <p>{pageContext.description}</p>
        <img src={image}/>
        <nav><ul>
            {pageContext.links && pageContext.links.map((linkEl, linkElIdx)=>{
                return (<li key={linkEl.url + "_" + linkElIdx}><a href={linkEl.url}>{linkEl.url}</a></li>)})}
            </ul></nav>
        </>
    );
}

export default BotPageRoom;